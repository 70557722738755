<template>
  <div>
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section-shaped my-0">
        <div class="shape shape-style-1 shape-default shape-skew">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container d-flex">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-12">
                <h1 class="display-3 text-white">咕噜的习性</h1>
                <h2 class="display-3 text-white">The Habits of Goolus</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- 1st Hero Variation -->
    </div>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-12 order-md-1">
            <h4 style="text-indent: 50px">
              作为世界上最可爱的生物，咕噜有着许多有趣的的习性。
            </h4>
            <h4 style="text-indent: 50px">
              As the cutest creature in the world, Goolu has many interesting habits.
            </h4>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg bg-secondary">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-lg-8 order-lg-1">
            <div class="d-flex px-3">
              <div>
                <icon name="ni ni-sound-wave" size="lg" class="bg-gradient-white" color="primary" shadow rounded>
                </icon>
              </div>
              <div class="pl-4">
                <h4 class="display-3">咕噜叫声</h4>
                <h5>研究表明, 现存咕噜主要以"拿"等叫声为主要情绪表达方式.</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-grid align-items-center">
          <div class="col-md-12 order-md-1">
            <table class="table">
              <thead>
                <tr>
                  <th style="word-wrap:break-word;word-break:break-all;">习性</th>
                  <th style="word-wrap:break-word;word-break:break-all;">小恐龙</th>
                  <th style="word-wrap:break-word;word-break:break-all;">小憨哥</th>
                </tr>
              </thead>
              <tbody>
                <!-- 叫声(高兴) -->
                <tr>
                  <td style="word-wrap:break-word;word-break:break-all;">叫声(高兴)</td>
                  <td style="word-wrap:break-word;word-break:break-all;">
                    恐龙, 拿
                    <base-button type="primary" class="btn btn-primary btn-icon btn-sm" @click="playLongHappySound()">
                      <i class="ni ni-button-play pt-1"></i>
                    </base-button>
                  </td>
                  <td style="word-wrap:break-word;word-break:break-all;">
                    拿
                    <link rel="hanSoundAudio" href="habit/han_sound.mp4" />
                    <base-button type="primary" class="btn btn-primary btn-icon btn-sm" @click="playHanHappySound() ">
                      <i class="ni ni-button-play pt-1"></i>
                    </base-button>
                  </td>
                </tr>
                <!-- 叫声(装傻) -->
                <tr>
                  <td style="word-wrap:break-word;word-break:break-all;">叫声(装傻)</td>
                  <td style="word-wrap:break-word;word-break:break-all;">
                    恐龙, 拿
                    <base-button type="primary" class="btn btn-primary btn-icon btn-sm" @click="playLongStupidSound()">
                      <i class="ni ni-button-play pt-1"></i>
                    </base-button>
                  </td>
                  <td style="word-wrap:break-word;word-break:break-all;">
                    拿
                    <link rel="hanSoundAudio" href="habit/han_sound.mp4" />
                    <base-button type="primary" class="btn btn-primary btn-icon btn-sm" @click="playHanStupidSound() ">
                      <i class="ni ni-button-play pt-1"></i>
                    </base-button>
                  </td>
                </tr>
                <!-- 叫声(平静) -->
                <tr>
                  <td style="word-wrap:break-word;word-break:break-all;">叫声(平静)</td>
                  <td style="word-wrap:break-word;word-break:break-all;">
                    恐龙, 拿
                    <base-button type="primary" class="btn btn-primary btn-icon btn-sm" @click="playLongCalmSound()">
                      <i class="ni ni-button-play pt-1"></i>
                    </base-button>
                  </td>
                  <td style="word-wrap:break-word;word-break:break-all;">
                    拿
                    <link rel="hanSoundAudio" href="habit/han_sound.mp4" />
                    <base-button type="primary" class="btn btn-primary btn-icon btn-sm" @click="playHanCalmSound() ">
                      <i class="ni ni-button-play pt-1"></i>
                    </base-button>
                  </td>
                </tr>
                <!-- 叫声(委屈) -->
                <tr>
                  <td style="word-wrap:break-word;word-break:break-all;">叫声(委屈)</td>
                  <td style="word-wrap:break-word;word-break:break-all;">
                    恐龙, 拿
                    <base-button type="primary" class="btn btn-primary btn-icon btn-sm" @click="playLongWrongedSound()">
                      <i class="ni ni-button-play pt-1"></i>
                    </base-button>
                  </td>
                  <td style="word-wrap:break-word;word-break:break-all;">
                    拿
                    <link rel="hanSoundAudio" href="habit/han_sound.mp4" />
                    <base-button type="primary" class="btn btn-primary btn-icon btn-sm" @click="playHanWrongedSound() ">
                      <i class="ni ni-button-play pt-1"></i>
                    </base-button>
                  </td>
                </tr>
                <!-- 叫声(生气) -->
                <tr>
                  <td style="word-wrap:break-word;word-break:break-all;">叫声(生气)</td>
                  <td style="word-wrap:break-word;word-break:break-all;">
                    恐龙, 拿
                    <base-button type="primary" class="btn btn-primary btn-icon btn-sm" @click="playLongAngrySound()">
                      <i class="ni ni-button-play pt-1"></i>
                    </base-button>
                  </td>
                  <td style="word-wrap:break-word;word-break:break-all;">
                    (被禁止了)
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-lg-8 order-lg-1">
            <div class="d-flex px-3">
              <div>
                <icon name="ni ni-collection" size="lg" class="bg-gradient-white" color="info" shadow rounded>
                </icon>
              </div>
              <div class="pl-4">
                <h4 class="display-3">年度词汇</h4>
                <h5>咕噜的交流中, 有很多奇妙的小词词, 咕噜们每年都会评选出一些年度词汇!</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-grid align-items-center">
          <div class="col-md-12 order-md-1">
            <table class="table">
              <thead>
                <tr>
                  <th style="word-wrap:break-word;word-break:break-all;width:30%">年份</th>
                  <th style="word-wrap:break-word;word-break:break-all;">词汇</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="word-wrap:break-word;word-break:break-all;width:30%">2022</td>
                  <td style="word-wrap:break-word;word-break:break-all;">gigagoga!</td>
                </tr>
                <tr>
                  <td style="word-wrap:break-word;word-break:break-all;width:30%">2023</td>
                  <td style="word-wrap:break-word;word-break:break-all;">对哦!</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "habit",
  components: {
    Modal,
  },
  data() {
    return {
      modals: {
        longSoundModal: false,
        hanSoundModal: false,
      }
    };
  },
  methods: {
    playLongHappySound() {
      var audio = new Audio("habit/shir_happy_sound.m4a")
      audio.volume = 0.3
      audio.play()
    },
    playHanHappySound() {
      var audio = new Audio("habit/han_happy_sound.mp4")
      audio.volume = 1
      audio.play()
    },
    playLongStupidSound() {
      var audio = new Audio("habit/shir_stupid_sound.m4a")
      audio.volume = 0.5
      audio.play()
    },
    playHanStupidSound() {
      (new Audio("habit/han_stupid_sound.mp4")).play()
    },
    playLongCalmSound() {
      var audio = new Audio("habit/shir_calm_sound.m4a")
      audio.volume = 0.3
      audio.play()
    },
    playHanCalmSound() {
      (new Audio("habit/han_calm_sound.mp4")).play()
    },
    playLongWrongedSound() {
      var audio = new Audio("habit/shir_wronged_sound.m4a")
      audio.volume = 0.5
      audio.play()
    },
    playHanWrongedSound() {
      (new Audio("habit/han_wronged_sound.mp4")).play()
    },
    playLongAngrySound() {
      var audio = new Audio("habit/shir_angry_sound.m4a")
      audio.volume = 0.5
      audio.play()
    },
  }
};
</script>
