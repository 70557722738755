<template>
  <div>
    <div class="position-relative">
      <section class="section-shaped my-0">
        <div class="shape shape-style-1 shape-default bg-gradient-info shape-skew">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container d-flex">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-12">
                <h1 class="display-1 text-white">
                  Portfolio - Lake Tahoe & Ski Resorts
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-4 order-md-1">
            <div class="pr-md-5"></div>
          </div>
          <div class="col-md-12 order-md-2">
            <div class="pr-md-5">
              <h3>
                <icon name="ni ni-like-2" class="mb-5" size="lg" type="info" shadow rounded></icon>
                Many ski resorts here!
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg">
      <div class="d-flex px-3">
        <div>
          <icon name="ni ni-calendar-grid-58" size="lg" class="bg-gradient-white" color="warning" shadow rounded>
          </icon>
        </div>
        <div class="pl-4">
          <h4 class="display-3 text-white">Palisades Tahoe Ski Resort</h4>
          <h5 class="text-white">2023/12</h5>
        </div>
      </div>
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-6 order-md-1">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/tahoe/202312/beach.jpeg" class="img-fluid floating" />
            </div>
          </div>
          <div class="col-md-6 order-md-1">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/tahoe/202312/ski.jpeg" class="img-fluid floating" />
            </div>
          </div>
          <!-- <div class="col-md-6 order-md-2">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/new_york/202309/mashed_photatos.jpeg" class="img-fluid floating" />
              <img src="img/new_york/202309/steak.jpeg" class="img-fluid floating" />
            </div>
          </div> -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import VideoModal from "@/components/VideoModal.vue"
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import {
  BCarousel
} from "bootstrap-vue/esm/components/carousel/carousel";
import {
  BCarouselSlide
} from "bootstrap-vue/esm/components/carousel/carousel-slide";

export default {
  name: "tahoe",
  components: {
    Modal,
    BCarousel,
    BCarouselSlide,
    VideoModal
  },
};
</script>
