<template>
<div>
    <div class="position-relative">
        <!-- shape Hero -->
        <section class="section-shaped my-0">
            <div class="shape shape-style-1 shape-default shape-skew">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div class="container shape-container d-flex">
                <div class="col px-0">
                    <div class="row">
                        <div class="col-lg-6">
                            <h1 class="display-3 text-white">
                                Shankirley's Message Board
                                <span>Welcome to leave your messages here~</span>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- 1st Hero Variation -->
    </div>
    <section class="section section-lg pt-lg-0 mt--200">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="row row-grid">
                        <template v-for="record in msg_records">
                            <div class="col-lg-4" :key="record[0] + record[1]">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="ni ni-like-2" type="primary" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-primary text-uppercase">{{ record[0] }}</h6>
                                    <p class="description mt-3">{{ record[1] }}</p>
                                </card>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="section section-shaped my-0 overflow-hidden">
        <div class="shape shape-style-3 bg-gradient-default shape-skew">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="pt-lg pb-150" />
    </section>
    <section class="section section-lg pt-lg-0 section-contact-us">
        <div class="container" id="message_submit_box">
            <div class="row justify-content-center mt--300">
                <div class="col-lg-8">
                    <card gradient="secondary" shadow body-classes="p-lg-5">
                        <h4 class="mb-1">Leave your message here!</h4>
                        <p class="mt-0">come on!(Still buggy)</p>
                        <base-input class="mt-5" alternative placeholder="Your name" addon-left-icon="ni ni-circle-08" v-model="name">
                        </base-input>
                        <base-input class="mb-4">
                            <textarea class="form-control form-control-alternative" name="name" rows="4" cols="80" placeholder="Type a message..." v-model="message" @keyup.enter="handleSubmit"></textarea>
                        </base-input>
                        <base-button type="default" round block size="lg" @click="handleSubmit">
                            Submit
                        </base-button>
                    </card>
                </div>
            </div>
        </div>
    </section>
</div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            msg_records: [
                ["小憨憨", "你们要永远幸福哦!"]
            ],
            name: '',
            message: '',
        }
    },
    methods: {
        handleSubmit() {
            axios({
                url: 'http://www.shankirley.art:5000/message_board/submit_message',
                method: 'post',
                data: {
                    'name': this.name,
                    'message': this.message
                }
            }).then((res) => {
                let data = res.data
                console.log(res.data)
                this.msg_records = data
                this.name = ''
                this.message = ''
            }).catch((err) => {
                console.log(err)
            })
        },
    },
    created: function () {
        axios({
            url: 'http://www.shankirley.art:5000/message_board/fetchall_message',
            method: 'get'
        }).then((res) => {
            let data = res.data
            console.log(data)
            this.msg_records = data
        }).catch((err) => {
            console.log(err)
        })
    },
}
</script>
