<template>
  <div>
    <div class="position-relative">
      <section class="section-shaped my-0">
        <div class="shape shape-style-1 shape-default bg-gradient-warning shape-skew">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container d-flex">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-12">
                <h1 class="display-1 text-white">
                  Portfolio - California
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-4 order-md-1">
            <div class="pr-md-5"></div>
          </div>
          <div class="col-md-12 order-md-2">
            <div class="pr-md-5">
              <h3>
                <icon name="ni ni-like-2" class="mb-5" size="lg" type="warning" shadow rounded></icon>
                California is a great state where Longgulu lives.
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section section-shaped my-0 overflow-hidden">
      <div class="shape shape-style-1 bg-gradient-danger shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container py-0">
        <div class="row row-grid align-items-center">
          <div class="col-lg-7 order-lg-1">
            <div class="d-flex px-3">
              <div>
                <icon name="ni ni-calendar-grid-58" size="lg" class="bg-gradient-white" color="danger" shadow rounded>
                </icon>
              </div>
              <div class="pl-4">
                <h4 class="display-3 text-white">Our First Date in the U.S.</h4>
                <h5 class="text-white">In San Francisco, California</h5>
                <h5 class="text-white">In 2022/11</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="container py-0">
          <div class="row row-grid align-items-center">
            <div class="col-md-6">
              <card shadow class="shadow-lg--hover mt-5">
                <div class="d-flex px-3">
                  <div class="pl-4">
                    <h5 class="title text-danger">It's been a long day without you, <br>my love</h5>
                    <h5 class="title text-danger">And I'll tell you all about it when <br>I <u>see you again</u></h5>
                  </div>
                </div>
              </card>
            </div>
            <div class="col-md-6">
              <div class="col-lg-16 mb-lg-auto">
                <div class="rounded shadow-lg overflow-hidden transform-perspective-right">
                  <b-carousel id="carousel1" :interval=1000 controls indicators>
                    <b-carousel-slide img-src="img/california/202211/2.jpg">
                    </b-carousel-slide>
                    <b-carousel-slide img-src="img/california/202211/3.jpg">
                    </b-carousel-slide>
                    <b-carousel-slide img-src="img/california/202211/4.jpg">
                    </b-carousel-slide>
                    <b-carousel-slide img-src="img/california/202211/5.jpg">
                    </b-carousel-slide>
                    <b-carousel-slide img-src="img/california/202211/6.jpg">
                    </b-carousel-slide>
                    <b-carousel-slide img-src="img/california/202211/7.jpg">
                    </b-carousel-slide>
                    <b-carousel-slide img-src="img/california/202211/8.jpg">
                    </b-carousel-slide>
                    <b-carousel-slide img-src="img/california/202211/9.jpg">
                    </b-carousel-slide>
                  </b-carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-6 order-md-1">
            <div class="col-lg-10 mb-lg-auto">
              <img src="img/california/202211/1.jpg" class="img-fluid floating" />
            </div>
            <br>
          </div>
          <div class="col-md-6 order-md-2">
            <div class="pr-md-5">
              <icon name="ni ni-like-2" class="mb-5" size="lg" type="danger" shadow rounded></icon>
              <h3>Hank to CA</h3>
              <h5>San Francisco, California</h5>
              <h5>2022/11</h5>
              <div class="col-md-12">
                <div class="col-md-12 order-md-2">
                  <ul class="list-unstyled mt-4">
                    <li class="py-2">
                      <div class="d-flex align-items-center">
                        <badge type="danger" circle class="mr-3" icon="ni ni-check-bold"></badge>
                        <h6 class="mb-0">Playing at Fisherman's Wharf</h6>
                      </div>
                    </li>
                    <li class="py-2">
                      <div class="d-flex align-items-center">
                        <badge type="danger" circle class="mr-3" icon="ni ni-check-bold"></badge>
                        <h6 class="mb-0">Eating at Fog Harbor Fish House</h6>
                      </div>
                    </li>
                    <li class="py-2">
                      <div class="d-flex align-items-center">
                        <badge type="danger" circle class="mr-3" icon="ni ni-check-bold"></badge>
                        <h6 class="mb-0">Watching World Cup</h6>
                      </div>
                    </li>
                    <li class="py-2">
                      <div class="d-flex align-items-center">
                        <badge type="danger" circle class="mr-3" icon="ni ni-check-bold"></badge>
                        <h6 class="mb-0">Meeting Shirley's friends</h6>
                      </div>
                    </li>
                    <li class="py-2">
                      <div class="d-flex align-items-center">
                        <badge type="danger" circle class="mr-3" icon="ni ni-check-bold"></badge>
                        <h6 class="mb-0">And All the love with Shirley!!!</h6>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg section-shaped my-0 overflow-hidden">
      <div class="shape shape-style-1 bg-gradient-primary shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container py-0">
        <div class="row row-grid align-items-center">
          <div class="col-lg-7 order-lg-1">
            <div class="d-flex px-3">
              <div>
                <icon name="ni ni-calendar-grid-58" size="lg" class="bg-gradient-white" color="primary" shadow rounded>
                </icon>
              </div>
              <div class="pl-4"> 
                <h4 class="display-3 text-white">See in CA Again</h4>
                <h5 class="text-white">In San Francisco, California</h5>
                <h5 class="text-white">In 2023/03</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container py-0">
        <div class="row row-grid align-items-center">
          <div class="col-md-5">
            <card shadow class="shadow-lg--hover mt-5">
              <div class="d-flex px-3">
                <div class="pl-4">
                  <h5 class="title text-primary">Things in Stanford.</h5>
                </div>
              </div>
            </card>
          </div>
          <div class="col-md-3">
            <div class="col-lg-16 mb-lg-auto">
              <div class="rounded overflow-hidden transform-perspective-right">
                <img src="img/california/202303/han.jpeg" class="img-fluid floating" />
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-lg-16 mb-lg-auto">
              <div class="rounded overflow-hidden transform-perspective-right">
                <video width="304" height="540" controls class="shadow-lg">
                  <source src="img/california/202303/202303.mp4" type="video/mp4">
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-6">
            <div class="col-lg-12 mb-lg-auto">
              <div class="rounded shadow-lg overflow-hidden transform-perspective-left">
                <b-carousel id="carousel1" :interval=1200 controls indicators>
                  <b-carousel-slide img-src="img/lego/lamborghini1.jpeg"></b-carousel-slide>
                  <b-carousel-slide img-src="img/lego/lamborghini2.jpeg"></b-carousel-slide>
                  <b-carousel-slide img-src="img/lego/hathaway.jpeg"></b-carousel-slide>
                  <b-carousel-slide img-src="img/lego/lego_flower.jpeg"></b-carousel-slide>
                </b-carousel>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="pl-md-5">
              <icon name="ni ni-atom" class="mb-5" size="lg" type="primary" shadow rounded></icon>
              <h3>Build every block of our love together!</h3>
              <p class="lead">Legos we built!</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg section-shaped my-0 overflow-hidden">
      <div class="shape shape-style-1 bg-gradient-info shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container py-0">
        <div class="row row-grid align-items-center">
          <div class="col-lg-8 order-lg-1">
            <div class="d-flex px-3">
              <div>
                <icon name="ni ni-calendar-grid-58" size="lg" class="bg-gradient-white" color="info" shadow rounded>
                </icon>
              </div>
              <div class="pl-4"> 
                <h4 class="display-3 text-white">A Fantastic Christmas in CA</h4>
                <h5 class="text-white">In San Francisco, California</h5>
                <h5 class="text-white">In 2023/12</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-4 order-md-1">
            <div class="col-lg-10 mb-lg-auto">
              <img src="img/california/202312/twin_peek_han.jpeg" class="img-fluid floating" />
              <img src="img/california/202312/twin_peek_long.jpeg" class="img-fluid floating" />
            </div>
          </div>
          <div class="col-md-4 order-md-2">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/california/202312/twin_peek_both.jpeg" class="img-fluid floating" />
              <img src="img/california/202312/twin_peek.jpeg" class="img-fluid floating" />
            </div>
          </div>
          <div class="col-md-4 order-md-3">
            <div class="pr-md-5">
              <icon name="ni ni-compass-04" class="mb-5" size="lg" type="info" shadow rounded></icon>
              <h3>Twin Peek & Hotpot</h3>
              <ul class="list-unstyled mt-5">
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="info" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">认识新昆虫: 任务蓝蝶!</h6>
                  </div>
                  <div class="d-flex align-items-center">
                    <badge type="info" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">一圈吃不完的肉</h6>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-md-10 order-md-2">
                <img src="img/california/202312/hotpot.jpeg" class="img-fluid floating" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section bg-secondary">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-6">
            <div class="rounded shadow-lg overflow-hidden transform-perspective-left">
              <div class="card bg-default shadow border-0">
                <img v-lazy="'img/california/202312/slippers.jpeg'" class="card-img-top" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="pt-md-1">
              <icon name="ni ni-bulb-61" class="mb-5" size="lg" type="info" shadow rounded></icon>
              <h3>Longgulu forgot to buy slippers...</h3>
              <div class="col-md-4">
                <video-modal :video_src="'img/california/202312/slippers.mp4'"
                  :poster_src="'img/california/202312/slippers.jpeg'" :additionalOptions="{aspectRatio: '12:9'}"></video-modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section section-shaped my-0 overflow-hidden">
      <div class="shape shape-style-1 bg-gradient-danger shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container py-0">
        <div class="row row-grid align-items-center">
          <div class="col-lg-7 order-lg-1">
            <div class="d-flex px-3">
              <div>
                <icon name="ni ni-calendar-grid-58" size="lg" class="bg-gradient-white" color="danger" shadow rounded>
                </icon>
              </div>
              <div class="pl-4">
                <h4 class="display-3 text-white">The Death Valley</h4>
                <h5 class="text-white">In California</h5>
                <h5 class="text-white">In 2023/12</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="container py-0">
          <div class="row row-grid align-items-center">
            <div class="col-md-8">
              <card shadow class="shadow-lg--hover mt-5">
                <div class="d-flex px-3">
                  <div class="pl-4">
                    <h5 class="title text-danger">Witnessed by the valley, the lake, and the sunset.</h5>
                    <h5 class="title text-danger">We stargazing and kissing, until forever.</h5>
                  </div>
                </div>
              </card>
            </div>
            <div class="col-md-4">
              <div class="col-lg-16 mb-lg-auto">
                <div class="rounded shadow-lg overflow-hidden transform-perspective-right">
                  <img src="img/california/202312/star.jpeg" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-4 order-md-1">
              <img src="img/california/202312/death_valley_han.jpeg" class="img-fluid floating" />
          </div>
          <div class="col-md-4 order-md-2">
              <img src="img/california/202312/death_valley_both.jpeg" class="img-fluid floating" />
          </div>
          <div class="col-md-4 order-md-3">
              <img src="img/california/202312/death_valley_long.jpeg" class="img-fluid floating" />
          </div>
        </div>
        <div class="row row-grid align-items-center">
          <div class="col-md-3 order-md-1">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/california/202312/death_valley1.jpeg" class="img-fluid floating" />
            </div>
          </div>
          <div class="col-md-3 order-md-2">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/california/202312/death_valley_sunset1.jpeg" class="img-fluid floating" />
            </div>
          </div>
          <div class="col-md-3 order-md-3">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/california/202312/death_valley_sunset2.jpeg" class="img-fluid floating" />
            </div>
          </div>
          <div class="col-md-3 order-md-3">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/california/202312/death_valley_sunset3.jpeg" class="img-fluid floating" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import VideoModal from "@/components/VideoModal.vue"
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import {
  BCarousel
} from "bootstrap-vue/esm/components/carousel/carousel";
import {
  BCarouselSlide
} from "bootstrap-vue/esm/components/carousel/carousel-slide";

export default {
  name: "california",
  components: {
    Modal,
    BCarousel,
    BCarouselSlide,
    VideoModal
  },
};
</script>
