<template>
  <div>
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section-shaped my-0">
        <div class="shape shape-style-1 shape-default shape-skew">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container d-flex">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-12">
                <h1 class="display-3 text-white">咕噜世界的音乐</h1>
                <h2 class="display-3 text-white">Music in Goolu's World</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- 1st Hero Variation -->
    </div>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-6 order-md-2">
            <img src="" class="img-fluid floating" />
          </div>
          <div class="col-md-12 order-md-1">
            <div class="pr-md-5">
              <h4 style="text-indent: 50px">
                在奇妙的咕噜世界里，有很多有趣的音乐！
              </h4>
              <h4 style="text-indent: 50px">
                There are many interesting music in the fantastic Goolu's World!
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section section-shaped my-0 overflow-hidden">
      <div class="shape shape-style-1 bg-gradient-warning">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container py-0">
        <div class="row row-grid align-items-center">
          <div class="col-lg-7 order-lg-1">
            <div class="d-flex px-3">
              <div>
                <icon name="ni ni-headphones" size="lg" class="bg-gradient-white" color="warning" shadow rounded>
                </icon>
              </div>
              <div class="pl-4">
                <h4 class="display-3 text-white">《憨咕噜与珑咕噜的故事》</h4>
                <h5 class="text-white">词：咕噜们</h5>
                <h5 class="text-white">曲：咕噜们</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="container py-0">
          <div class="row row-grid align-items-center">
            <div class="col-md-6">
              <card shadow class="shadow-lg--hover mt-5">
                <div class="d-flex px-3">
                  <div class="pl-4">
                    <h5 class="title text-warning">憨咕噜是最憨的憨咕噜！</h5>
                    <h5 class="title text-warning">珑咕噜是最珑的珑咕噜！</h5>
                    <h5 class="title text-warning">憨咕噜是憨！</h5>
                    <h5 class="title text-warning">珑咕噜是珑！</h5>
                    <h5 class="title text-warning">珑珑的憨咕噜爱珑咕噜！</h5>
                    <h5 class="title text-warning">憨憨的珑咕噜爱憨咕噜！</h5>
                    <h5 class="title text-warning">咕噜咕噜咕！</h5>
                    <h5 class="title text-warning">噜咕噜咕噜！</h5>
                  </div>
                </div>
              </card>
            </div>
            <div class="col-md-6">
              <div class="col-lg-16 mb-lg-auto">
                <div class="rounded shadow-lg overflow-hidden transform-perspective-left"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section section-shaped my-0 overflow-hidden">
      <div class="shape shape-style-1 bg-gradient-danger">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container py-0">
        <div class="row row-grid align-items-center">
          <div class="col-lg-7 order-lg-1">
            <div class="d-flex px-3">
              <div>
                <icon name="ni ni-headphones" size="lg" class="bg-gradient-white" color="danger" shadow rounded>
                </icon>
              </div>
              <div class="pl-4">
                <h4 class="display-3 text-white">《亲爱的咕噜啊》</h4>
                <h5 class="text-white">词：咕噜们</h5>
                <h5 class="text-white">曲：木村弓</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="container py-0">
          <div class="row row-grid align-items-center">
            <div class="col-md-6">
              <card shadow class="shadow-lg--hover mt-5">
                <div class="d-flex px-3">
                  <div class="pl-4">
                    <h5 class="title text-danger">
                      小小的恐龙<br />真可爱，我们都喜欢她！
                    </h5>
                    <h5 class="title text-danger">
                      小小的憨哥<br />是傻逼，我们都很爱他！
                    </h5>
                  </div>
                </div>
              </card>
            </div>
            <div class="col-md-6">
              <div class="col-lg-16 mb-lg-auto">
                <div class="rounded shadow-lg overflow-hidden transform-perspective-left"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section section-shaped my-0 overflow-hidden">
      <div class="shape shape-style-1 bg-gradient-primary">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container py-0">
        <div class="row row-grid align-items-center">
          <div class="col-lg-12 order-lg-1">
            <div class="d-flex px-3">
              <div>
                <icon name="ni ni-headphones" size="lg" class="bg-gradient-white" color="primary" shadow rounded>
                </icon>
              </div>
              <div class="pl-4">
                <h4 class="display-3 text-white">《紫菜是紫菜，海带是海带，海苔是海苔》</h4>
                <h5 class="text-white">词：咕噜们</h5>
                <h5 class="text-white">曲：咕噜们</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="container py-0">
          <div class="row row-grid align-items-center">
            <div class="col-md-6">
              <card shadow class="shadow-lg--hover mt-5">
                <div class="d-flex px-3">
                  <div class="pl-4">
                    <h5 class="title text-primary">
                      紫菜是紫菜！
                    </h5>
                    <h5 class="title text-primary">
                      海带是海带!
                    </h5>
                    <h5 class="title text-primary">
                      海苔是海苔！
                    </h5>
                  </div>
                </div>
              </card>
            </div>
            <div class="col-md-6">
              <div class="col-lg-16 mb-lg-auto">
                <div class="rounded shadow-lg overflow-hidden transform-perspective-left"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "song",
  components: {
    Modal,
  },
};
</script>
