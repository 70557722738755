<template>
<div>
    <base-button block type="warning" class=" mb-3" @click="modal = true">
        See Video
    </base-button>
    <modal :show.sync="modal" gradient="warning" modal-classes="modal-danger modal-dialog-centered">
        <h6 slot="header" class="modal-title" id="modal-title-notification">A short video!</h6>
        <div class="py-3 text-center">
            <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
        </div>
        <br>
        <div class="text-center">
            <base-button type="white" @click="modal = false">Close</base-button>
        </div>
    </modal>
</div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import {
    videoPlayer
} from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')

export default {
    components: {
        Modal,
        videoPlayer
    },
    props: {
        video_src: {
            default: "video/yongtai.mp4",
            type: String,
            required: true,
        },
        poster_src: {
            type: String,
            required: true,
        },
        additionalOptions: {
            type: Object,
            default: () => {}
        },
    },
    data() {
        return {
            modal: false,
            defaultPlayerOptions: {
                playbackRates: [0.7, 1.0, 1.5, 2.0], // play speed
                autoplay: false, // play when your browser is ready
                muted: true, // whether to mute by default
                loop: false, // restart when it ends
                preload: 'auto', // whether to download when parsing <video>
                language: 'zh-CN',
                aspectRatio: '16:9', // proportion
                fluid: true, // suit container in proportion
                sources: [{
                    type: "video/mp4", // see github for more types
                    src: this.video_src // url of the video
                }],
                poster: this.poster_src, // cover of the video
                // width: document.documentElement.clientWidth, // width of the player
                notSupportedMessage: 'Something went wrong', // default message when the video cannot be played
                controlBar: {
                    timeDivider: true,
                    durationDisplay: true,
                    remainingTimeDisplay: false,
                    fullscreenToggle: true
                }
            }
        };
    },
    computed: {
        playerOptions: function() {
            return {...this.defaultPlayerOptions, ...this.additionalOptions};
        }
    },
    watch: {
        'modal': function (new_modal) {
            if (new_modal == false) {
                this.$refs.videoPlayer.player.pause();
            }
        }
    }
};
</script>
