<template>
  <div>
      <hero></hero>
  </div>
</template>
<script>
import Hero from "./components/Hero";

export default {
name: "mainpage",
components: {
  Hero,
}
};
</script>
