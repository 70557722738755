<template>
  <div>
    <div class="position-relative">
      <section class="section-shaped my-0">
        <div class="shape shape-style-1 shape-default bg-gradient-warning shape-skew">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container d-flex">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-12">
                <h1 class="display-1 text-white">
                  Portfolio - Log Angeles
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-4 order-md-1">
            <div class="pr-md-5">
              <icon name="ni ni-like-2" class="mb-5" size="lg" type="warning" shadow rounded></icon>
              <h3>Visiting Los Santos</h3>
              <h5>In 2023/12</h5>
              <ul class="list-unstyled mt-5">
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="warning" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">Griffth Observatory</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="warning" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">Santa Monica Beach</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="warning" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">Hollywood Icon</h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-4 order-md-2">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/los_angeles/202312/hollywood.jpeg" class="img-fluid floating" />
            </div>
          </div>
          <div class="col-md-4 order-md-3">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/los_angeles/202312/griffth.jpeg" class="img-fluid floating" />
              <img src="img/los_angeles/202312/santa_monica.jpeg" class="img-fluid floating" />
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-3 order-md-1">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/los_angeles/202312/palm.jpeg" class="img-fluid floating" />
            </div>
          </div>
          <div class="col-md-3 order-md-2">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/los_angeles/202312/getty.jpeg" class="img-fluid floating" />
            </div>
          </div>
          <div class="col-md-3 order-md-3">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/los_angeles/202312/city_hall.jpeg" class="img-fluid floating" />
            </div>
          </div>
          <div class="col-md-3 order-md-4">
            <div class="pr-md-5">
              <!-- <icon name="ni ni-like-2" class="mb-5" size="lg" type="warning" shadow rounded></icon>
              <h3>Visiting Los Santos.</h3> -->
              <ul class="list-unstyled mt-5">
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="warning" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">Palm</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="warning" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">Getty Museum</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="warning" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">Happy New Year!</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="warning" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">Love Cloud</h6>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/los_angeles/202312/love_cloud.jpeg" class="img-fluid floating" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg section-shaped my-0 overflow-hidden">
      <div class="shape shape-style-1 bg-gradient-primary shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container py-0">
        <div class="row row-grid align-items-center">
          <div class="col-lg-8 order-lg-1">
            <div class="d-flex px-3">
              <div>
                <icon name="ni ni-bulb-61" size="lg" class="bg-gradient-white" color="primary" shadow rounded>
                </icon>
              </div>
              <div class="pl-4">
                <h4 class="display-3 text-white">Enchanted Forest of Light</h4>
                <h5 class="text-white">In 2023/12</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-grid align-items-center">
          <div class="col-md-6">
            <card shadow class="shadow-lg--hover mt-5">
              <div class="d-flex px-3">
                <div class="pl-4">
                  <h5 class="title text-primary">This is a magical place, </h5>
                  <h5 class="title text-primary">full of love and romantics.</h5>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-3 order-md-1">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/los_angeles/202312/forest1.jpeg" class="img-fluid floating" />
              <br />
              <img src="img/los_angeles/202312/forest2.jpeg" class="img-fluid floating" />
            </div>
          </div>
          <div class="col-md-3 order-md-2">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/los_angeles/202312/forest5.jpeg" class="img-fluid floating" />
              <br />
              <img src="img/los_angeles/202312/forest3.jpeg" class="img-fluid floating" />
            </div>
          </div>
          <div class="col-md-3 order-md-2">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/los_angeles/202312/forest4.jpeg" class="img-fluid floating" />
              <br />
              <img src="img/los_angeles/202312/forest6.jpeg" class="img-fluid floating" />
            </div>
          </div>
          <div class="col-md-3 order-md-3">
            <div class="pr-md-5">
              <icon name="ni ni-compass-04" class="mb-5" size="lg" type="primary" shadow rounded></icon>
              <ul class="list-unstyled mt-5">
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <h6 class="mb-0">
                      <badge type="primary" circle class="mr-3" icon="ni ni-check-bold"></badge>
                      Breathing Flowers
                    </h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <h6 class="mb-0">
                      <badge type="primary" circle class="mr-3" icon="ni ni-check-bold"></badge>
                      Forest of Fireflies
                    </h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <h6 class="mb-0">
                      <badge type="primary" circle class="mr-3" icon="ni ni-check-bold"></badge>
                      Ghost Under Oak
                    </h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="primary" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">
                      Ghost Behind House
                    </h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <h6 class="mb-0">
                      <badge type="primary" circle class="mr-3" icon="ni ni-check-bold"></badge>
                      And Drink!
                    </h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import VideoModal from "@/components/VideoModal.vue"
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import {
  BCarousel
} from "bootstrap-vue/esm/components/carousel/carousel";
import {
  BCarouselSlide
} from "bootstrap-vue/esm/components/carousel/carousel-slide";

export default {
  name: "los_angeles",
  components: {
    Modal,
    BCarousel,
    BCarouselSlide,
    VideoModal
  },
};
</script>
