<template>
  <div>
    <div class="wrapper">
      <section class="section-shaped my-0">
        <div class="shape shape-style-2 shape-default bg-gradient-success">
          <span class="span-150"></span>
          <span class="span-50"></span>
          <span class="span-50"></span>
          <span class="span-75"></span>
          <span class="span-100"></span>
          <span class="span-75"></span>
          <span class="span-50"></span>
          <span class="span-100"></span>
          <span class="span-50"></span>
          <span class="span-100"></span>
        </div>
        <div class="container shape-container d-flex">
          <div class="col-lg-12">
            <h1 class="display-1 text-white">
              Portfolio - Lego
            </h1>
          </div>
        </div>
      </section>
    </div>
    <!-- <div class="position-relative">
      <section class="section-shaped my-0">
        <div class="shape shape-style-1 shape-default bg-gradient-success shape-skew">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container d-flex">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-12">
                <h1 class="display-1 text-white">
                  Portfolio - Lego
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div> -->
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-4 order-md-1">
            <div class="pr-md-5"></div>
          </div>
          <div class="col-md-12 order-md-2">
            <h3>
              <icon name="ni ni-atom" class="mb-5" size="lg" type="success" shadow rounded></icon>
              Build every block of our love together!
            </h3>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-12 mt-5">
            <div class="row row-grid">
              <div class="col-lg-6">
                <card class="border-0" hover shadow body-classes="py-3">
                  <h4 class="text-success text-uppercase">Hathaway</h4>
                  <p class="text-success">2022/08, China</p>
                  <img src="img/lego/hathaway.jpeg" class="img-fluid" />
                </card>
              </div>
              <div class="col-lg-6">
                <card class="border-0" hover shadow body-classes="py-3">
                  <h4 class="text-success text-uppercase">Flower</h4>
                  <p class="text-success">2022/08, China</p>
                  <img src="img/lego/lego_flower.jpeg" class="img-fluid" />
                </card>
              </div>
            </div>
          </div>
          <div class="col-lg-12 mt-5">
            <div class="row row-grid">
              <div class="col-lg-6">
                <card class="border-0" hover shadow body-classes="py-3">
                  <h4 class="text-success text-uppercase">Lamborghini</h4>
                  <p class="text-success">2023/03, California</p>
                  <img src="img/lego/lamborghini1.jpeg" class="img-fluid" />
                </card>
              </div>
              <div class="col-lg-6">
                <card class="border-0" hover shadow body-classes="py-3">
                  <h4 class="text-success text-uppercase">Lamborghini</h4>
                  <p class="text-success">2023/03, California</p>
                  <img src="img/lego/lamborghini2.jpeg" class="img-fluid" />
                </card>
              </div>
            </div>
          </div>
          <div class="col-lg-12 mt-5">
            <div class="row row-grid">
              <div class="col-lg-6">
                <card class="border-0" hover shadow body-classes="py-3">
                  <h4 class="text-success text-uppercase">Dragon</h4>
                  <p class="text-success">2024/01, California</p>
                  <img src="img/lego/dragon1.jpeg" class="img-fluid" />
                </card>
              </div>
              <div class="col-lg-6">
                <card class="border-0" hover shadow body-classes="py-3">
                  <h4 class="text-success text-uppercase">Dragon</h4>
                  <p class="text-success">2024/01, California</p>
                  <img src="img/lego/dragon2.jpeg" class="img-fluid" />
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import VideoModal from "@/components/VideoModal.vue"
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import {
  BCarousel
} from "bootstrap-vue/esm/components/carousel/carousel";
import {
  BCarouselSlide
} from "bootstrap-vue/esm/components/carousel/carousel-slide";

export default {
  name: "lego",
  components: {
    Modal,
    BCarousel,
    BCarouselSlide,
    VideoModal
  },
};
</script>
