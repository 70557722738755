<template>
  <div>
    <div class="position-relative">
      <section class="section-shaped my-0">
        <div class="shape shape-style-1 shape-default bg-gradient-danger shape-skew">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container d-flex">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-12">
                <h1 class="display-1 text-white">
                  Portfolio - China
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section class="section section-lg pt-lg-0 mt--200">
      <div class="container">
      </div>
    </section>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-4 order-md-1">
            <div class="pr-md-5"></div>
          </div>
          <div class="col-md-12 order-md-2">
            <div class="pr-md-5">
              <h3>
                <icon name="ni ni-like-2" class="mb-5" size="lg" type="danger" shadow rounded></icon>
                China is our home country!
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-secondary">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-6 order-md-2">
            <img src="img/china/mount_huang.jpg" class="img-fluid floating" />
          </div>
          <div class="col-md-6 order-md-1">
            <div class="pr-md-5">
              <icon name="ni ni-square-pin" class="mb-5" size="lg" type="success" shadow rounded></icon>
              <h3>Our First Travel - 黄山</h3>
              <p>在一起一个多月, 一起去了黄山旅行~</p>
              <ul class="list-unstyled mt-5">
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="success" circle class="mr-3" icon="ni ni-sound-wave"></badge>
                    <h6 class="mb-0">穿短袖短裤的傻逼憨哥</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="success" circle class="mr-3" icon="ni ni-user-run"></badge>
                    <h6 class="mb-0">一起走到腿断 gigigi!</h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section bg-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-6">
            <div class="card bg-default shadow border-0">
              <img v-lazy="'img/china/yongtai/yongtai.jpg'" class="card-img-top" />
              <blockquote class="card-blockquote">
                <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95" class="svg-bg">
                  <polygon points="0,52 583,95 0,95" class="fill-default"></polygon>
                  <polygon points="0,42 583,95 683,0 0,95" opacity=".2" class="fill-default">
                  </polygon>
                </svg>
                <h4 class="display-3 font-weight-bold text-white">福建-永泰</h4>
                <p class="lead text-italic text-white">陪你走到夕阳尽头~</p>
              </blockquote>
            </div>
          </div>
          <div class="col-md-6">
            <div class="pl-md-5">
              <icon name="ni ni-square-pin" class="mb-5" size="lg" type="warning" shadow rounded></icon>

              <h3>福州-永泰</h3>
              <h5>2020/06/30</h5>
              <p class="lead">和小伙伴们一行五人.</p>
              <div class="col-md-4">
                <video-modal :video_src="'video/yongtai.mp4'"
                  :poster_src="'img/china/yongtai/yongtai_video_poster.jpg'"></video-modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-secondary">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-6 order-md-1">
            <div class="pr-md-5">
              <icon name="ni ni-square-pin" class="mb-5" size="lg" type="primary" shadow rounded></icon>
              <h3>在我们的家乡 - 泉州</h3>
              <h5>2020/05/03</h5>
              <p>海上银滩</p>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="row row-grid">
              <div class="col-lg-4">
                <card class="border-0" hover shadow body-classes="py-5">
                  <h4 class="text-success text-uppercase">憨憨</h4>
                  <img src="img/china/yintan/han.jpg" class="img-fluid" />
                </card>
              </div>
              <div class="col-lg-4">
                <card class="border-0" hover shadow body-classes="py-5">
                  <h4 class="text-success text-uppercase">珑珑放风筝!</h4>
                  <img src="img/china/yintan/shirley_kite.jpg" class="img-fluid" />
                </card>
              </div>
              <div class="col-lg-4">
                <card class="border-0" hover shadow body-classes="py-5">
                  <h4 class="text-success text-uppercase">珑珑</h4>
                  <img src="img/china/yintan/long.jpg" class="img-fluid" />
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section bg-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-6 order-md-1">
            <div class="pr-md-5">
              <icon name="ni ni-square-pin" class="mb-5" size="lg" type="primary" shadow rounded></icon>
              <h3>憨哥进食</h3>
              <p>正常版</p>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="row row-grid">
              <div class="col-lg-4">
                <card class="border-0" hover shadow body-classes="py-5">
                  <h4 class="text-success text-uppercase"></h4>
                  <img src="img/china/eat/hank_normal_eat_1.jpg" class="img-fluid" />
                </card>
              </div>
              <div class="col-lg-4">
                <card class="border-0" hover shadow body-classes="py-5">
                  <h4 class="text-success text-uppercase"></h4>
                  <img src="img/china/eat/hank_normal_eat_2.jpg" class="img-fluid" />
                </card>
              </div>
              <div class="col-lg-4">
                <card class="border-0" hover shadow body-classes="py-5">
                  <h4 class="text-success text-uppercase"></h4>
                  <img src="img/china/eat/hank_normal_eat_3.jpg" class="img-fluid" />
                </card>
              </div>
              <!-- <div class="col-lg-4">
              <card class="border-0" hover shadow body-classes="py-5">
                <h4 class="text-success text-uppercase"></h4>
                <img src="img/china/eat/hank_normal_eat_4.jpg" class="img-fluid" />
              </card>
            </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-secondary">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-6 order-md-1">
            <div class="pr-md-5">
              <icon name="ni ni-square-pin" class="mb-5" size="lg" type="primary" shadow rounded></icon>
              <h3>憨哥进食</h3>
              <p>奇特版</p>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="row row-grid">
              <div class="col-lg-4">
                <card class="border-0" hover shadow body-classes="py-5">
                  <h4 class="text-success text-uppercase"></h4>
                  <img src="img/china/eat/hank_abnormal_eat_1.jpg" class="img-fluid" />
                </card>
              </div>
              <div class="col-lg-4">
                <card class="border-0" hover shadow body-classes="py-5">
                  <h4 class="text-success text-uppercase"></h4>
                  <img src="img/china/eat/hank_abnormal_eat_2.jpg" class="img-fluid" />
                </card>
              </div>
              <!-- <div class="col-lg-4">
              <card class="border-0" hover shadow body-classes="py-5">
                <h4 class="text-success text-uppercase"></h4>
                <img src="img/china/eat/hank_abnormal_eat_3.jpg" class="img-fluid" />
              </card>
            </div> -->
              <div class="col-lg-4">
                <card class="border-0" hover shadow body-classes="py-5">
                  <h4 class="text-success text-uppercase"></h4>
                  <img src="img/china/eat/hank_abnormal_eat_4.jpg" class="img-fluid" />
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section section-shaped my-0 overflow-hidden">
      <div class="shape shape-style-1 bg-gradient-warning shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container py-0">
        <div class="row row-grid align-items-center">
          <div class="col-md-6 order-lg-2 ml-lg-auto">
            <div class="position-relative pl-md-5">
              <img src="img/china/anniversary1/cake.jpg" class="img-center img-fluid" />
            </div>
          </div>
          <div class="col-lg-6 order-lg-1">
            <div class="d-flex px-3">
              <div>
                <icon name="ni ni-calendar-grid-58" size="lg" class="bg-gradient-white" color="primary" shadow rounded>
                </icon>
              </div>
              <div class="pl-4">
                <h4 class="display-3 text-white">The First Anniversary</h4>
                <h5 class="text-white">2020/08/03</h5>
              </div>
            </div>
            <card shadow class="shadow-lg--hover mt-5">
              <div class="d-flex px-3">
                <div>
                  <icon name="ni ni-satisfied" gradient="success" color="white" shadow rounded></icon>
                </div>
                <div class="pl-4">
                  <h5 class="title text-success">Hank</h5>
                  <p></p>
                  <!-- <a href="#" class="text-success">Learn more</a> -->
                </div>
              </div>
            </card>
            <card shadow class="shadow-lg--hover mt-5">
              <div class="d-flex px-3">
                <div>
                  <icon name="ni ni-satisfied" gradient="warning" color="white" shadow rounded></icon>
                </div>
                <div class="pl-4">
                  <h5 class="title text-warning">Shirley</h5>
                  <p></p>
                  <!-- <a href="#" class="text-warning">Learn more</a> -->
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-6 order-md-2">
            <div class="col-lg-10 mb-lg-auto">
              <img src="img/china/spring_festival/szrp1.jpg" class="img-fluid floating" />
            </div>
          </div>
          <div class="col-md-6 order-md-1">
            <div class="pr-md-5">
              <icon name="ni ni-like-2" class="mb-5" size="lg" type="success" shadow rounded></icon>
              <h3>2021 年春节假期</h3>
              <p>在憨哥家里</p>
              <h5>2021/02</h5>
              <ul class="list-unstyled mt-5">
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="success" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">一起做水煮肉片!</h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section bg-secondary">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-6">
            <div class="col-lg-12 mb-lg-auto">
              <div class="rounded shadow-lg overflow-hidden transform-perspective-left">
                <b-carousel id="carousel1" :interval=1200 controls indicators>
                  <b-carousel-slide img-src="img/china/xiaocai/xiaocai20210318.jpg"></b-carousel-slide>
                  <b-carousel-slide img-src="img/china/xiaocai/xiaocai20210326.jpg"></b-carousel-slide>
                  <b-carousel-slide img-src="img/china/xiaocai/xiaocai20210502.jpg"></b-carousel-slide>
                  <b-carousel-slide img-src="img/china/xiaocai/xiaocai20210506-0.jpg"></b-carousel-slide>
                  <b-carousel-slide img-src="img/china/xiaocai/xiaocai20210506-1.jpg"></b-carousel-slide>
                  <b-carousel-slide img-src="img/china/xiaocai/xiaocai20210506-2.jpg"></b-carousel-slide>
                </b-carousel>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="pl-md-5">
              <icon name="ni ni-favourite-28" class="mb-5" size="lg" type="warning" shadow rounded></icon>
              <h3>小菜的一生</h3>
              <h5>2021/03/18 - 2021/05/06</h5>
              <p class="lead">爱情的结晶——小菜</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-4 order-md-2">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/china/guangzhou/1.jpg" class="img-fluid floating" />
              <img src="img/china/guangzhou/2.jpg" class="img-fluid floating" />
            </div>
          </div>
          <div class="col-md-4 order-md-2">
            <div class="col-lg-16 mb-lg-auto">
              <img src="img/china/guangzhou/3.jpg" class="img-fluid floating" />
            </div>
          </div>
          <div class="col-md-4 order-md-1">
            <div class="pr-md-5">
              <icon name="ni ni-like-2" class="mb-5" size="lg" type="success" shadow rounded></icon>
              <h3>Trip to Guangzhou</h3>
              <h5>广州, Guangzhou</h5>
              <h5>2022/05</h5>
              <ul class="list-unstyled mt-5">
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="success" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">广州塔</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="success" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">融创雪世界</h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section section-shaped my-0 overflow-hidden">
      <div class="shape shape-style-1 bg-gradient-success shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container py-0">
        <div class="row row-grid align-items-center">
          <div class="col-lg-6 order-lg-1">
            <div class="d-flex px-3">
              <div>
                <icon name="ni ni-calendar-grid-58" size="lg" class="bg-gradient-white" color="primary" shadow rounded>
                </icon>
              </div>
              <div class="pl-4">
                <h4 class="display-3 text-white">Graduate from USTC</h4>
                <p class="text-white">In 2022/06</p>
              </div>
            </div>
          </div>
        </div>
        <div class="container py-0">
          <div class="row row-grid align-items-center">
            <div class="col-md-5">
              <card shadow class="shadow-lg--hover mt-5">
                <div class="d-flex px-3">
                  <div>
                    <icon name="ni ni-satisfied" gradient="success" color="white" shadow rounded></icon>
                  </div>
                  <div class="pl-4">
                    <h5 class="title text-success">❤❤❤❤❤❤❤❤❤</h5>
                  </div>
                </div>
              </card>
            </div>
            <div class="col-md-7">
              <div class="col-lg-16 mb-lg-auto">
                <div class="rounded shadow-lg overflow-hidden transform-perspective-left">
                  <b-carousel id="carousel1" :interval=1000 controls indicators>
                    <b-carousel-slide img-src="img/china/graduate_ustc/1.jpg">
                    </b-carousel-slide>
                    <b-carousel-slide img-src="img/china/graduate_ustc/2.jpg">
                    </b-carousel-slide>
                    <b-carousel-slide img-src="img/china/graduate_ustc/3.jpg">
                    </b-carousel-slide>
                    <b-carousel-slide img-src="img/china/graduate_ustc/4.jpg">
                    </b-carousel-slide>
                  </b-carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-6 order-md-2">
            <div class="col-lg-10 mb-lg-auto">
              <img src="img/china/hunan/1.jpg" class="img-fluid floating" />
            </div>
            <br>
          </div>
          <div class="col-md-6 order-md-1">
            <div class="pr-md-5">
              <icon name="ni ni-like-2" class="mb-5" size="lg" type="success" shadow rounded></icon>
              <h3>Graduation Trip</h3>
              <h5>湖南, Hunan</h5>
              <h5>2022/07</h5>
              <div class="col-md-12">
                <div class="col-md-8 order-md-2">
                  <ul class="list-unstyled mt-4">
                    <li class="py-2">
                      <div class="d-flex align-items-center">
                        <badge type="success" circle class="mr-3" icon="ni ni-check-bold"></badge>
                        <h6 class="mb-0">张家界</h6>
                      </div>
                    </li>
                    <li class="py-2">
                      <div class="d-flex align-items-center">
                        <badge type="success" circle class="mr-3" icon="ni ni-check-bold"></badge>
                        <h6 class="mb-0">长沙</h6>
                      </div>
                    </li>
                    <li class="py-2">
                      <div class="d-flex align-items-center">
                        <badge type="success" circle class="mr-3" icon="ni ni-check-bold"></badge>
                        <h6 class="mb-0">通宵到岳麓山看日出~</h6>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import VideoModal from "@/components/VideoModal.vue"
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import {
  BCarousel
} from "bootstrap-vue/esm/components/carousel/carousel";
import {
  BCarouselSlide
} from "bootstrap-vue/esm/components/carousel/carousel-slide";

export default {
  name: "china",
  components: {
    Modal,
    BCarousel,
    BCarouselSlide,
    VideoModal
  },
};
</script>
