<template>
  <div>
    <div class="position-relative">
      <section class="section-shaped my-0">
        <div class="shape shape-style-1 shape-default bg-gradient-danger shape-skew">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container d-flex">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-12">
                <h1 class="display-1 text-white">
                  Portfolio - Las Vegas
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-4 order-md-1">
            <div class="pr-md-5"></div>
          </div>
          <div class="col-md-12 order-md-2">
            <h3>
              <icon name="ni ni-like-2" class="mb-5" size="lg" type="danger" shadow rounded></icon>
              Where we lost money from gambling and speeding 😡
            </h3>
          </div>
        </div>
      </div>
    </section>
    <section class="section section section-shaped my-0 overflow-hidden">
      <div class="shape shape-style-1 bg-gradient-danger shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container py-0">
        <div class="row row-grid align-items-center">
          <div class="col-lg-7 order-lg-1">
            <div class="d-flex px-3">
              <div>
                <icon name="ni ni-calendar-grid-58" size="lg" class="bg-gradient-white" color="danger" shadow rounded>
                </icon>
              </div>
              <div class="pl-4">
                <h4 class="display-3 text-white">First trip to Las Vegas</h4>
                <h5 class="text-white">In 2023/12</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="container py-0">
          <div class="row row-grid align-items-center">
            <div class="col-md-6">
              <card shadow class="shadow-lg--hover mt-5">
                <div class="d-flex px-3">
                  <div class="pl-4">
                    <h5 class="title text-danger">FIB Warning:</h5>
                    <h5 class="title text-danger">❌ Do Not Touch Gambling</h5>
                    <h5 class="title text-danger">❌ Do Not Speed</h5>
                  </div>
                </div>
              </card>
            </div>
            <div class="col-md-6">
              <div class="col-lg-16 mb-lg-auto">
                <div class="rounded shadow-lg overflow-hidden transform-perspective-right">
                  <b-carousel id="carousel1" :interval=1000 controls indicators>
                    <b-carousel-slide img-src="img/las_vegas/202312/voucher.jpeg">
                    </b-carousel-slide>
                    <b-carousel-slide img-src="img/las_vegas/202312/street1.jpeg">
                    </b-carousel-slide>
                    <b-carousel-slide img-src="img/las_vegas/202312/street2.jpeg">
                    </b-carousel-slide>
                  </b-carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-4 order-md-1">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/las_vegas/202312/sushi1.jpeg" class="img-fluid floating" />
              <img src="img/las_vegas/202312/sushi2.jpeg" class="img-fluid floating" />
            </div>
          </div>
          <div class="col-md-4 order-md-2">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/las_vegas/202312/sushi3.jpeg" class="img-fluid floating" />
              <img src="img/las_vegas/202312/sushi4.jpeg" class="img-fluid floating" />
            </div>
          </div>
          <div class="col-md-4 order-md-2">
            <div class="pr-md-5">
              <icon name="ni ni-like-2" class="mb-5" size="lg" type="danger" shadow rounded></icon>
              <h3>All You Can Eat</h3>
              <ul class="list-unstyled mt-5">
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="danger" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">Sushi</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="danger" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">Sushi</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="danger" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">And Sushi</h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section bg-secondary">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-2"></div>
          <div class="col-md-4">
            <div class="pt-md-1">
              <icon name="ni ni-satisfied" class="mb-5" size="lg" type="warning" shadow rounded></icon>
              <h3>The Sphere</h3>
              <div class="col-md-6">
                <video-modal :video_src="'img/las_vegas/202312/sphere.mp4'"
                  :poster_src="'img/las_vegas/202312/sphere.jpeg'" :additionalOptions="{aspectRatio: '1:1'}"></video-modal>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="rounded shadow-lg overflow-hidden transform-perspective-right">
              <div class="card bg-default shadow border-0">
                <img v-lazy="'img/las_vegas/202312/sphere.jpeg'" class="card-img-top" />
              </div>
            </div>
          </div>
          <div class="col-md-2"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import VideoModal from "@/components/VideoModal.vue"
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import {
  BCarousel
} from "bootstrap-vue/esm/components/carousel/carousel";
import {
  BCarouselSlide
} from "bootstrap-vue/esm/components/carousel/carousel-slide";

export default {
  name: "las_vegas",
  components: {
    Modal,
    BCarousel,
    BCarouselSlide,
    VideoModal
  },
};
</script>
