<template>
  <div>
    <div class="position-relative">
      <section class="section-shaped my-0">
        <div class="shape shape-style-1 shape-default bg-gradient-success shape-skew">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container d-flex">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-12">
                <h1 class="display-1 text-white">
                  Portfolio - New Orlean
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-4 order-md-1">
            <div class="pr-md-5"></div>
          </div>
          <div class="col-md-12 order-md-2">
            <div class="pr-md-5">
              <h3>
                <icon name="ni ni-like-2" class="mb-5" size="lg" type="success" shadow rounded></icon>
                Hangulu enjoys free hotel when Longgulu went for Neurips.
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section section-shaped my-0 overflow-hidden">
      <div class="shape shape-style-1 bg-gradient-info shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container py-0">
        <div class="row row-grid align-items-center">
          <div class="col-lg-7 order-lg-1">
            <div class="d-flex px-3">
              <div>
                <icon name="ni ni-calendar-grid-58" size="lg" class="bg-gradient-white" color="info" shadow rounded>
                </icon>
              </div>
              <div class="pl-4">
                <h4 class="display-3 text-white">New Orleans!</h4>
                <h5 class="text-white">In New Orleans</h5>
                <h5 class="text-white">2023/12</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="container py-0">
          <div class="row row-grid align-items-center">
            <div class="col-md-5">
              <card shadow class="shadow-lg--hover mt-5">
                <div class="d-flex px-3">
                  <div class="pl-4">
                    <h5 class="title text-info">3 bars in a single night. </h5>
                    <h5 class="title text-info">(And got COVID...)</h5>
                    <h5 class="title text-info">Cute animals!</h5>
                  </div>
                </div>
              </card>
            </div>
            <div class="col-md-3">
              <div class="col-lg-16 mb-lg-auto">
                <div class="rounded shadow-lg overflow-hidden transform-perspective-left">
                  <img src="img/new_orleans/202312/tiger.jpeg" class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="col-lg-16 mb-lg-auto">
                <div class="rounded shadow-lg overflow-hidden transform-perspective-left">
                  <img src="img/new_orleans/202312/bar_street.jpeg" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import VideoModal from "@/components/VideoModal.vue"
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import {
  BCarousel
} from "bootstrap-vue/esm/components/carousel/carousel";
import {
  BCarouselSlide
} from "bootstrap-vue/esm/components/carousel/carousel-slide";

export default {
  name: "new_orleans",
  components: {
    Modal,
    BCarousel,
    BCarouselSlide,
    VideoModal
  },
};
</script>
