<template>
  <div>
    <div class="position-relative">
      <section class="section-shaped my-0">
        <div class="shape shape-style-1 shape-default bg-gradient-info shape-skew">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container d-flex">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-12">
                <h1 class="display-1 text-white">
                  Portfolio - New York
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-4 order-md-1">
            <div class="pr-md-5"></div>
          </div>
          <div class="col-md-12 order-md-2">
            <div class="pr-md-5">
              <h3>
                <icon name="ni ni-like-2" class="mb-5" size="lg" type="info" shadow rounded></icon>
                New York is a great city where Hangulu lives.
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section section-shaped my-0 overflow-hidden">
      <div class="shape shape-style-1 bg-gradient-info shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container py-0">
        <div class="row row-grid align-items-center">
          <div class="col-lg-7 order-lg-1">
            <div class="d-flex px-3">
              <div>
                <icon name="ni ni-calendar-grid-58" size="lg" class="bg-gradient-white" color="info" shadow rounded>
                </icon>
              </div>
              <div class="pl-4">
                <h4 class="display-3 text-white">Welcome to New York!</h4>
                <h5 class="text-white">In New York City</h5>
                <h5 class="text-white">2022/12 - 2023/01</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="container py-0">
          <div class="row row-grid align-items-center">
            <div class="col-md-7">
              <card shadow class="shadow-lg--hover mt-5">
                <div class="d-flex px-3">
                  <div class="pl-4">
                    <h5 class="title text-info">开开心心过大年</h5>
                  </div>
                </div>
              </card>
            </div>
            <div class="col-md-5">
              <div class="col-lg-10 mb-lg-auto">
                <div class="rounded shadow-lg overflow-hidden transform-perspective-left">
                  <b-carousel id="carousel1" :interval=1000 controls indicators>
                    <b-carousel-slide img-src="img/new_york/202212/blue.jpg">
                    </b-carousel-slide>
                    <b-carousel-slide img-src="img/new_york/202212/grey.jpg">
                    </b-carousel-slide>
                    <b-carousel-slide img-src="img/new_york/202212/orange.jpg">
                    </b-carousel-slide>
                    <b-carousel-slide img-src="img/new_york/202212/red.jpg">
                    </b-carousel-slide>
                    <b-carousel-slide img-src="img/new_york/202212/silver.jpg">
                    </b-carousel-slide>
                    <b-carousel-slide img-src="img/new_york/202212/clean.jpg">
                    </b-carousel-slide>
                  </b-carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-4 order-md-1">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/new_york/202212/imilky.jpg" class="img-fluid floating" />
              <img src="img/new_york/202212/hancurry.jpg" class="img-fluid floating" />
            </div>
          </div>
          <div class="col-md-4 order-md-2">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/new_york/202212/sushi.jpg" class="img-fluid floating" />
              <img src="img/new_york/202212/gogocurry.jpg" class="img-fluid floating" />
            </div>
          </div>
          <div class="col-md-4 order-md-2">
            <div class="pr-md-5">
              <icon name="ni ni-like-2" class="mb-5" size="lg" type="info" shadow rounded></icon>
              <h3>Enjoy Foods Together</h3>
              <ul class="list-unstyled mt-5">
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="info" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">Milky Tea</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="info" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">Curry</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="info" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">Sushi</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="info" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">...</h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section bg-secondary">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-6 order-md-1">
            <div class="pr-md-5">
              <icon name="ni ni-square-pin" class="mb-5" size="lg" type="info" shadow rounded></icon>
              <h3>City Views</h3>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="row row-grid">
              <div class="col-lg-3">
                <card class="border-0" hover shadow body-classes="py-2">
                  <h4 class="text-success text-uppercase"></h4>
                  <img src="img/new_york/202212/empire_building.jpg" class="img-fluid" />
                </card>
              </div>
              <div class="col-lg-3">
                <card class="border-0" hover shadow body-classes="py-2">
                  <h4 class="text-success text-uppercase"></h4>
                  <img src="img/new_york/202212/times.jpg" class="img-fluid" />
                </card>
              </div>
              <div class="col-lg-3">
                <card class="border-0" hover shadow body-classes="py-2">
                  <h4 class="text-success text-uppercase"></h4>
                  <img src="img/new_york/202212/christmas_tree.jpg" class="img-fluid" />
                </card>
              </div>
              <div class="col-lg-3">
                <card class="border-0" hover shadow body-classes="py-2">
                  <h4 class="text-success text-uppercase"></h4>
                  <img src="img/new_york/202212/brooklyn_bridge.jpg" class="img-fluid" />
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-2">
          </div>
          <div class="col-md-4">
            <div class="card bg-default shadow border-0">
              <img v-lazy="'img/new_york/202212/shrimp.jpg'" class="card-img-top" />
              <blockquote class="card-blockquote">
                <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95" class="svg-bg">
                  <polygon points="0,52 583,95 0,95" class="fill-default"></polygon>
                  <polygon points="0,42 583,95 683,0 0,95" opacity=".2" class="fill-default">
                  </polygon>
                </svg>
                <h4 class="display-3 font-weight-bold text-white">小虾的一生</h4>
                <p class="lead text-italic text-white">爱情的结晶——小虾</p>
              </blockquote>
            </div>
          </div>
          <div class="col-md-6">
            <div class="pl-md-5">
              <icon name="ni ni-favourite-28" class="mb-5" size="lg" type="warning" shadow rounded></icon>

              <h3>小虾的一生</h3>
              <h5>2022/12/25 - 2022/12/??</h5>
              <div class="col-md-4">
                <video-modal :video_src="'img/new_york/202212/shrimp.mp4'"
                  :poster_src="'img/new_york/202212/shrimp.jpg'" :additionalOptions="{aspectRatio: '9:16'}"></video-modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg section-shaped my-0 overflow-hidden">
      <div class="shape shape-style-1 bg-gradient-success shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container py-0">
        <div class="row row-grid align-items-center">
          <div class="col-lg-7 order-lg-1">
            <div class="d-flex px-3">
              <div>
                <icon name="ni ni-calendar-grid-58" size="lg" class="bg-gradient-white" color="success" shadow rounded>
                </icon>
              </div>
              <div class="pl-4"> 
                <h4 class="display-3 text-white">A Flash in NY</h4>
                <h5 class="text-white">In New York</h5>
                <h5 class="text-white">In 2023/05</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="container py-0">
          <div class="row row-grid align-items-center">
            <div class="col-md-4">
              <div class="col-lg-16 mb-lg-auto">
                <div class="rounded shadow-lg overflow-hidden transform-perspective-left">
                  <img src="/img/new_york/202305/uniqlo2.jpeg" class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="col-lg-16 mb-lg-auto">
                <div class="rounded shadow-lg overflow-hidden transform-perspective-left">
                  <img src="/img/new_york/202305/uniqlo1.jpeg" class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <card shadow class="shadow-lg--hover mt-5">
                <div class="d-flex px-3">
                  <div class="pl-4">
                    <h5 class="title text-success">Short but frequent.</h5>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-4 order-md-1">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/new_york/202305/fish.jpeg" class="img-fluid floating" />
              <img src="img/new_york/202305/zhangliang.jpeg" class="img-fluid floating" />
            </div>
          </div>
          <div class="col-md-4 order-md-2">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/new_york/202305/galaxy.jpeg" class="img-fluid floating" />
              <img src="img/new_york/202305/mario.jpeg" class="img-fluid floating" />
            </div>
          </div>
          <div class="col-md-4 order-md-2">
            <div class="pr-md-5">
              <icon name="ni ni-planet" class="mb-5" size="lg" type="info" shadow rounded></icon>
              <h3>Enjoy Foods Together</h3>
              <ul class="list-unstyled mt-5">
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="info" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">Jiangnan Fusion</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="info" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">张亮麻辣烫</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="info" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">Guardians of the Galaxy</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="info" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">The Super Mario Bros</h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-3 order-md-2">
            <div class="pr-md-5">
              <h4>Museum of Sex</h4>
              <ul class="list-unstyled mt-3">
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="info" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">An Auto Wedding in Muesum of Sex</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="info" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">A bottle of "Love Potion"</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="info" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">A weird slide...</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="info" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">Flower from Grove Street Market</h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-3 order-md-2">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/new_york/202305/wedding.jpg" class="img-fluid floating" />
              <img src="img/new_york/202305/potion.png" class="img-fluid floating" />
            </div>
          </div>
          <div class="col-md-3 order-md-2">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/new_york/202305/slide.jpeg" class="img-fluid floating" />
            </div>
          </div>
          <div class="col-md-3 order-md-2">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/new_york/202305/flower.jpg" class="img-fluid floating" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section section-shaped my-0 overflow-hidden">
      <div class="shape shape-style-1 bg-gradient-warning shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container py-0">
        <div class="row row-grid align-items-center">
          <div class="col-lg-7 order-lg-1">
            <div class="d-flex px-3">
              <div>
                <icon name="ni ni-calendar-grid-58" size="lg" class="bg-gradient-white" color="warning" shadow rounded>
                </icon>
              </div>
              <div class="pl-4">
                <h4 class="display-3 text-white">NJ & NY</h4>
                <h5 class="text-white">2023/09</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="container py-0">
          <div class="row row-grid align-items-center">
            <div class="col-md-7">
              <card shadow class="shadow-lg--hover mt-5">
                <div class="d-flex px-3">
                  <div class="pl-4">
                    <h5 class="title text-warning"><badge type="warning" circle class="mr-3" icon="ni ni-favourite-28"></badge>Hadestown</h5>
                    <h5 class="title text-warning"><badge type="warning" circle class="mr-3" icon="ni ni-world"></badge>Palisades Interstate Park</h5>
                    <h5 class="title text-warning"><badge type="warning" circle class="mr-3" icon="ni ni-palette"></badge>Storm King Art Center</h5>
                  </div>
                </div>
              </card>
            </div>
            <div class="col-md-5">
              <div class="col-lg-10 mb-lg-auto">
                <div class="rounded shadow-lg overflow-hidden transform-perspective-right">
                  <b-carousel id="carousel1" :interval=1000 controls indicators>
                    <b-carousel-slide img-src="img/new_york/202309/hadestown1.jpeg">
                    </b-carousel-slide>
                    <b-carousel-slide img-src="img/new_york/202309/hadestown2.jpeg">
                    </b-carousel-slide>
                    <b-carousel-slide img-src="img/new_york/202309/palisades_park.jpeg">
                    </b-carousel-slide>
                    <b-carousel-slide img-src="img/new_york/202309/storm_king_art_center1.jpeg">
                    </b-carousel-slide>
                    <b-carousel-slide img-src="img/new_york/202309/storm_king_art_center2.jpeg">
                    </b-carousel-slide>
                  </b-carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-4 order-md-1">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/new_york/202309/peking_duck.jpeg" class="img-fluid floating" />
              <img src="img/new_york/202309/lamb.jpeg" class="img-fluid floating" />
            </div>
          </div>
          <div class="col-md-4 order-md-2">
            <div class="col-lg-12 mb-lg-auto">
              <img src="img/new_york/202309/mashed_photatos.jpeg" class="img-fluid floating" />
              <img src="img/new_york/202309/steak.jpeg" class="img-fluid floating" />
            </div>
          </div>
          <div class="col-md-4 order-md-2">
            <div class="pr-md-5">
              <icon name="ni ni-like-2" class="mb-5" size="lg" type="warning" shadow rounded></icon>
              <h3>And Foods Again!</h3>
              <ul class="list-unstyled mt-5">
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="warning" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">Peking Duck</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="warning" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">Lamb</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="warning" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">Mashed Photatos</h6>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <badge type="warning" circle class="mr-3" icon="ni ni-check-bold"></badge>
                    <h6 class="mb-0">Steak</h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import VideoModal from "@/components/VideoModal.vue"
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import {
  BCarousel
} from "bootstrap-vue/esm/components/carousel/carousel";
import {
  BCarouselSlide
} from "bootstrap-vue/esm/components/carousel/carousel-slide";

export default {
  name: "new_york",
  components: {
    Modal,
    BCarousel,
    BCarouselSlide,
    VideoModal
  },
};
</script>
